import React from "react";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

export default function ScheduleAppointment() {

    const container: React.CSSProperties = {
        backgroundColor: '#219ebc',
        width: "100%",
        paddingTop: 50,
        paddingBottom: 50
    };

    const content: React.CSSProperties = {
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div style={container}>
            <Container>
                <div style={content}>
                    <div style={{ fontSize: 40, textAlign: 'center', lineHeight: 1, paddingBottom: 15  }}>
                        SCHEDULE AN APPOINTMENT
                    </div>
                    <div style={{ fontSize: 20, textAlign: 'center'}}>
                        If you are a new patient, click the button below to schedule your initial evaluation.
                    </div>

                    <div style={{ paddingTop: 20 }}>
                        <Button style={{ backgroundColor: '#0A3E62', borderColor: '#0A3E62' }} onClick={() => { window.open('https://betterdays.intakeq.com/booking', '_blank'); }} variant="primary">Schedule an Appointment</Button>
                    </div>

                    <div style={{ fontSize: 40, textAlign: 'center', lineHeight: 1, paddingTop: 20  }}>
                        EXSTING PATIENTS
                    </div>
                    <div style={{ fontSize: 20, textAlign: 'center'}}>
                        Are you already an established patient?  Log into the patient portal below.
                    </div>
                    <div style={{ paddingTop: 20 }}>
                        <Button style={{ backgroundColor: '#0A3E62', borderColor: '#0A3E62' }} onClick={() => { window.open('https://betterdays.intakeq.com/portal', '_blank'); }} variant="primary">Patient Portal</Button>
                    </div>

                </div>
            </Container>


        </div>
    )
}