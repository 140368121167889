import React from "react";

import Container from 'react-bootstrap/Container';

export default function Philosophy() {

    const content: React.CSSProperties = {
        paddingTop: 50,
        paddingBottom: 50,
    };

    const title: React.CSSProperties = {
        fontSize: 30,
    };

    const verbiage: React.CSSProperties = {
        fontSize: 18,
        padding: 5
    };

    return (
        <Container>
            <div style={content}>
                <div style={title}>Our Philosophy</div>
                <div style={verbiage}>Welcome to Better Days Psychiatry and Family Practice! We strive to provide high quality health care that focuses on improving mental and physical health.  We use an evidence-based methodology that follows a holistic approach to health care.  We collaborate with our patients and encourage them to focus on mental,  physical, emotional, social, and spiritual well-being.</div>
                <div style={verbiage}>At Better Days Psychiatry and Family Practice, we desire to provide health services to our clients through a long term trustworthy therapeutic relationship.  We use different modalities such as preventative measures, patient education, medication management and supportive counseling.  We are compassionate about listening empathetically to our clients needs and helping achieve their goals and improve their quality of life.</div>
            </div>
        </Container>
    )
}