import React from "react";
import { Container } from "react-bootstrap";

export default function Emergency() {

    const image: React.CSSProperties = {
        position: 'relative',
        backgroundImage: "url('../../emergency.jpg')",
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }

    const content: React.CSSProperties = {
        position: 'absolute',
    }

    const text: React.CSSProperties = {
        padding: 20,
        textAlign: 'center',
    };

    return (
        <div style={{ position: 'relative', height: 'auto' }}>
            <div style={image}>
                <Container>
                    <div style={{ padding: 30, textAlign: 'center' }}>
                        <p style={{ fontWeight: 'bold', fontSize: 24 }}>If You Are Experiencing A Life-Threatening Emergency, Feeling In Deep Distress, Or Have Thoughts Of Hurting Yourself Or Hurting Others, Please Go To Your Nearest Emergency Room Or Call 988 For Emergency Services.</p>

                        <div style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1, paddingTop: 20 }}>
                            <p><p>NATIONAL MENTAL HEALTH/SUICIDE HOTLINE</p></p>
                        </div>

                        <div style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1, paddingTop: 20 }}>
                            <p>Available 24/7</p>
                            <p>Text "CONNECT" to 741-741</p>
                        </div>

                        <div style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1, paddingTop: 20 }}>
                            <p>Other Crisis Lines:</p>
                        </div>

                        <div style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1, paddingTop: 20 }}>
                            <p>National Suicide Prevention Lifeline</p>
                            <p>1-800-273-8255</p>
                        </div>

                        <div style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1, paddingTop: 20 }}>
                            <p>Mobile Crisis Response through CICS</p>
                            <p>1-844-258-8858</p>
                        </div>

                        <div style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1, paddingTop: 20 }}>
                            <p>ACCESS Domestic Violence Services</p>
                            <p>1-855-983-4641</p>
                        </div>

                        <div style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1, paddingTop: 20 }}>
                            <p>Health, Community, and Social Services</p>
                            <p>211 info has free information about more than 7000 health, community, and social services.</p>
                        </div>

                    </div>

                </Container>
            </div>


        </div>

    )
}