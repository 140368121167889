import React from "react";
import { Container } from "react-bootstrap";

export default function Banner() {

    const image: React.CSSProperties = {
        position: 'relative',
        backgroundImage: "url('../../background1.jpg')",
        height: 700,
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }

    const logo: React.CSSProperties = {
        objectFit: 'cover',
        height: 350,
    };

    return (
        <div style={{ position: 'relative', height: 'auto' }}>
            <div style={image}>
                <Container style={{height: '100%', paddingTop: 55}}>
                    <div style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={logo} src="../../logo.png"></img>
                    </div>

                </Container>
            </div>


        </div>

    )
}