import React, { useState } from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export interface IMenuProps {
    navigateTo: (elementName: string) => void;
}

export default function Menu(props: IMenuProps) {

    const Logo: React.CSSProperties = {
        objectFit: 'cover',
        height: 75,
    };

    const MenuOption: React.CSSProperties = {
        fontSize: 20,
        cursor: 'pointer',
        color: 'white',
    };

    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expand="lg" className="bg-body-tertiary" fixed="top" expanded={expanded}>
            <Container>
                <Navbar.Brand>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            alt=""
                            src="../../BetterDaysMenu.png"
                            width="50"
                            height="50"
                            className="d-inline-block align-top"
                        />{' '} Better Days
                    </div></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link onClick={() => {
                            props.navigateTo("home")
                            setExpanded(false)
                        }}>Home</Nav.Link>

                        <Nav.Link onClick={() => {
                            props.navigateTo("philosophy")
                            setExpanded(false)
                        }}>Philosophy</Nav.Link>

                        <Nav.Link onClick={() => {
                            props.navigateTo("providers")
                            setExpanded(false)
                        }}>Provider</Nav.Link>

                        <Nav.Link onClick={() => {
                            props.navigateTo("services")
                            setExpanded(false)
                        }}>Services</Nav.Link>

                        <Nav.Link onClick={() => {
                            props.navigateTo("faq")
                            setExpanded(false)
                        }}>FAQ</Nav.Link>

                        <Nav.Link onClick={() => {
                            props.navigateTo("emergency")
                            setExpanded(false)
                        }}>Emergency</Nav.Link>

                        <Nav.Link onClick={() => {
                            props.navigateTo("contact")
                            setExpanded(false)
                        }}>Contact</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>



    )
}