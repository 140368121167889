import React from "react";

import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface IServicesProps {

}

export default function Services(props: IServicesProps) {


    const content: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 50,
        paddingBottom: 50,
    };

    return (
        <Container>
            <div style={content}>
                <p style={{ fontSize: 30, margin: 0, paddingBottom: 25 }}>Services We Offer</p>

                <div>
                    <p style={{ fontSize: 24, margin: 0, fontWeight: 'bold'}}>Psychiatric Assessments</p>
                    <p>
                        <div style={{ display: 'flex', gap: 30, alignItems: 'center', maxWidth: 800 }}>
                            <div style={{ fontSize: 50 }}>
                                <FontAwesomeIcon icon="address-card" />
                            </div>
                            <div>
                                At Better Days Psychiatry and Family Practice we address virtually every aspect of psychiatric disorders - including depression, bipolar disorder, and a host of other disorders such as anxiety, panic, attention deficit, obsessive compulsive, post- partum depression and post-traumatic stress. We complete full psychiatry evaluations from ages 5 and up.
                            </div>
                        </div>
                    </p>
                </div>

                <div>
                    <p style={{ fontSize: 24, margin: 0, fontWeight: 'bold' }}>Children and Adolescents</p>
                    <p>
                        <div style={{ display: 'flex', gap: 30, alignItems: 'center', maxWidth: 800 }}>
                            <div style={{ fontSize: 50 }}>
                                <FontAwesomeIcon icon="children" />
                            </div>
                            <div>
                                We are experienced with managing psychiatric care across the lifespan and have several years’ experience managing care of children and adolescents.  We provide medication management for neurodevelopment disorders such as depression, ADHD, PTSD, anxiety and mood disorders. We currently do not do autism evaluations.
                            </div>
                        </div>
                    </p>
                </div>

                <div>
                    <p style={{ fontSize: 24, margin: 0, fontWeight: 'bold' }}>Family Practice</p>
                    <p>
                        <div style={{ display: 'flex', gap: 30, alignItems: 'center', maxWidth: 800 }}>
                            <div style={{ fontSize: 50 }}>
                                <FontAwesomeIcon icon="stethoscope" />
                            </div>
                            <div>
                                At Better Days Psychiatry and Family Practice we have over eleven years of experience with caring for patients across the life span. We treat patients from birth to geriatric to include multiple chronic conditions and acute illness.  We believe that treating the patient with medical and mental healthcare models helps to strengthen and improve their care holistically.  We do wellness exams, preventative medicine, management of chronic conditions and acute illness.
                            </div>
                        </div>
                    </p>
                </div>

                <div>
                    <p style={{ fontSize: 24, margin: 0, fontWeight: 'bold' }}>Telehealth</p>
                    <p>
                        <div style={{ display: 'flex', gap: 30, alignItems: 'center', maxWidth: 800 }}>
                            <div style={{ fontSize: 50 }}>
                                <FontAwesomeIcon icon="desktop" />
                            </div>
                            <div>
                                We at Better Days Psychiatry and Family Practice understand that our lives can become very busy, therefore, we strive to improve access to care.  We offer virtual visits for convenience from your home. You need to be able to connect to the internet either via computer, tablet, or phone.  We use a secure and HIPPA compliant platform to complete your visit. Telehealth visits are available to any Missouri resident.
                            </div>
                        </div>
                    </p>
                </div>

                <div>
                    <p style={{ fontSize: 24, margin: 0, fontWeight: 'bold' }}>Genetic Testing</p>
                    <p>
                        <div style={{ display: 'flex', gap: 30, alignItems: 'center', maxWidth: 800 }}>
                            <div style={{ fontSize: 50 }}>
                                <FontAwesomeIcon icon="dna" />
                            </div>
                            <div>
                                GeneSight is a genetic test that uses DNA gathered from a simple cheek swab to analyze a patient’s genes and help provide a personalized approach to prescribing medications. Each patient's genes influence their body's response to specific medications that my not work the same for everyone.  The GeneSight report is one piece of information that we use to develop an individualized plan for you.  Many government and commercial insurance plans cover Genesight. Insurances such as Medicare, Medicaid and Tricare cover this testing in full.  For more information visit <a href='https://genesight.com/' target="_blank">GeneSight</a>.
                            </div>
                        </div>
                    </p>
                </div>

            </div>


        </Container>
    )
}