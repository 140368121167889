import React from "react";

import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';

export default function FAQ() {

    const container: React.CSSProperties = {
        paddingTop: 50,
        paddingBottom: 50,
    };

    const content: React.CSSProperties = {
        padding: 20,
    };

    return (
        <Container style={container}>
            <p style={{ fontSize: 24 }}>FREQUENTLY ASKED QUESTIONS</p>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Do I need a referral?</Accordion.Header>
                    <Accordion.Body>
                        <div style={content}>
                            No, you do not need a referral for any of our services. We do have online intake forms to be completed prior to being seen.
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>What are the current rates?</Accordion.Header>
                    <Accordion.Body>
                        <div style={content}>
                            <p>Please inquire about our reduced rates if we do not currently take your insurance.</p>

                            <div style={{display: 'flex', flexDirection: 'column', gap: 1, padding: 5}}>
                                <span style={{fontWeight: 'bold'}}>Online Initial Psychiatric Evaluation</span>
                                <span>This online appointment is for NEW patients only.	60 min.	$200.00</span>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', gap: 1, padding: 5}}>
                                <span style={{fontWeight: 'bold'}}>In-Office Initial Psychiatric Evaluation</span>
                                <span>This in-office appointment is for NEW patients only.	60 min.	$200.00</span>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', gap: 1, padding: 5}}>
                                <span style={{fontWeight: 'bold'}}>Online Follow-Up Psychiatric Evaluation</span>
                                <span>This online appointment is for EXISTING patients only.	30 min.	$150.00</span>
                            </div> 

                            <div style={{display: 'flex', flexDirection: 'column', gap: 1, padding: 5}}>
                                <span style={{fontWeight: 'bold'}}>In-Office Follow-Up Psychiatric Evaluation</span>
                                <span>This in-office appointment is for EXISTING patients only.	30 min.	$150.00</span>
                            </div> 

                            <div style={{display: 'flex', flexDirection: 'column', gap: 1, padding: 5}}>
                                <span style={{fontWeight: 'bold'}}>Online Initial Family Practice Evaluation</span>
                                <span>This online appointment is for NEW patients only.	30 min.	$170.00</span>
                            </div> 

                            <div style={{display: 'flex', flexDirection: 'column', gap: 1, padding: 5}}>
                                <span style={{fontWeight: 'bold'}}>In-Office Initial Family Practice Evaluation</span>
                                <span>This in-office appointment is for NEW patients only.	30 min.	$170.00</span>
                            </div> 

                            <div style={{display: 'flex', flexDirection: 'column', gap: 1, padding: 5}}>
                                <span style={{fontWeight: 'bold'}}>Online Follow-Up Family Practice Evaluation</span>
                                <span>This online appointment is for EXISTING patients only.	20 min.	$135.00</span>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', gap: 1, padding: 5}}>
                                <span style={{fontWeight: 'bold'}}>In-Office Follow-Up Family Practice Evaluation</span>
                                <span>This in-office appointment is for EXISTING patients only.	20 min.	$135.00</span>
                            </div> 
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>What insurances do you accept?</Accordion.Header>
                    <Accordion.Body>
                        <div style={content}>
                            <p>We are currently taking Aetna, Cigna, and United Health Care insurances.</p>

                            <div>
                                <img src="./aetna.webp"></img>
                                <img src="./cigna.webp"></img>
                                <img src="./uhc.webp"></img>
                            </div>
                            <p>If you are self-pay, payment is expected before your visit with the provider.  We will provide a detailed receipt, called a “superbill” which can be submitted to your insurance company for possible reimbursement. Please consult with your insurance company for any questions prior to your visit.  We accept credit/debit cards, HSA and FSA cards.</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>Where are you located?</Accordion.Header>
                    <Accordion.Body>
                        <div style={content}>
                            We are located inside Revision Christian Counseling upstairs on second floor.  At 4171 Crescent Dr, Suite 202, St Louis, MO 63129.
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>Can I see you virtually?</Accordion.Header>
                    <Accordion.Body>
                        <div style={content}>
                            Yes, we've partnered with Google Meets for secured telehealth. If you live anywhere in the state of Missouri, you can see us for your high quality mental and primary health care needs.
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>What ages and populations do you treat?</Accordion.Header>
                    <Accordion.Body>
                        <div style={content}>
                            We see all patients and populations from the age of 5  years old and up for psychiatric care.  We see patients from birth to geriatric for primary care.
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}