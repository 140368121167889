import React from 'react';

import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faCalendar, faPhone, faMap, faEnvelope, faAddressCard, faChildren, faStethoscope, faDesktop, faDna } from '@fortawesome/free-solid-svg-icons'

import './App.scss'

import Banner from './Banner';
import Menu from './Menu';
import Philosophy from './Philosophy';
import ScheduleAppointment from './ScheduleAppointment';
import AboutHattie from './AboutHattie';
import FAQ from './FAQ';
import Footer from './Footer';
import Emergency from './Emergency';
import Services from './Services';

library.add(faCheckSquare, faCoffee, faCalendar, faPhone, faMap, faEnvelope, faAddressCard, faChildren, faStethoscope, faDesktop, faDna)

function App() {

  function handleNavigateTo(element: string) {

    let div = document.getElementById(element)
    if (div) {
      div.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div style={{color: '#0A3E62', fontFamily: 'Alegreya'}}>
      <Menu navigateTo={(element: string) => handleNavigateTo(element)}></Menu>

      <div id="home">
        <Banner></Banner>
      </div>

      <div id="philosophy">
        <Philosophy></Philosophy>
      </div>

      <div id="providers">
        <AboutHattie></AboutHattie>
      </div>

      <div id="services">
        <Services></Services>
      </div>

      <ScheduleAppointment></ScheduleAppointment>

      <div id="faq">
        <FAQ></FAQ>
      </div>

      <div id="emergency">
        <Emergency></Emergency>
      </div>

      <div id="contact">
        <Footer></Footer>
      </div>

    </div>
  );
}

export default App;
