import React, {useEffect, useState} from "react";

import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';



export default function AboutHattie() {

    const [direction, setDirection] = useState<'row' | 'row-reverse' | 'column' | 'column-reverse'>('row');

    const content: React.CSSProperties = {
        display: 'flex',
        flexDirection: direction,
        alignItems: 'start',
        paddingTop: 50,
        paddingBottom: 50,
    };

    const col: React.CSSProperties = {
        flex: 1,
    };

    const image: React.CSSProperties = {
        objectFit: 'contain',
        height: "100%",
        width: "100%",
        flexGrow: 2,
    }

    window.addEventListener('resize', () => {
        if(window.innerWidth > 1000) {
            setDirection('row')
        } else {
            setDirection('column')
        }
    })


    return (
        <Container>
            <div style={content}>
                <div style={col}>
                    <p style={{ fontSize: 30, margin: 0 }}>Hattie DeBord</p>
                    <p style={{ fontSize: 24, margin: 0, paddingBottom: 10 }}>MSN, APRN, PMHNP-BC, FNP-C</p>

                    <div style={{ fontSize: 18 }}>
                        <p>Hattie DeBord is a board-certified Family Nurse practitioner and Psychiatric Nurse practitioner who is dedicated, compassionate, and diligent.  She takes pride in serving children, adolescents and adults with their mental health and primary care needs. Ms. DeBord graduated with Honors with her master’s in nursing at University of Missouri St Louis in 2012 and has a post master’s certificate from Liberty University as a psychiatric nurse practitioner. She holds a bachelor’s degree from Truman State University.  Hattie is an Army veteran that served for three years and deployed to Operation Iraqi Freedom.</p>
                        <p>Patients see her for a variety of reasons, including pediatric consultations, postpartum depression, depression, anxiety, mood disorders, ADHD and eating disorders.  She also specializes in treating multiple chronic medical conditions such as diabetes, hypertension, hyperlipidemia, and asthma.  Ms. DeBord treats a wide range of conditions, and she is eager to help the wider community receive the care they deserve.  She believes that  healing the mind can also heal the body and enjoys treating the whole patient.  She is dedicated to listening to the patients’ specific needs and tailoring a plan of care for each individual. </p>

                        <p>EDUCATION, CERTIFICATIONS & TRAINING:</p>
                        <li>Truman State University BSN 2004</li>
                        <li>University of Missouri St Louis MSN 2012</li>
                        <li>Liberty University Post Graduate Certification 2022</li>
                        <li>American Association of Nurse Practitioners, Family Nurses Practitioner 2012</li>
                        <li>American Nurses Credentialing Center,  Psychiatric Mental Health Nurse Practitioner 2022</li>

                    </div>

                </div>

                <div style={col}>
                    <div style={{ padding: 20, alignItems: 'flex-start' }}>
                        <Image src="../../hattie.png" fluid />
                    </div>
                </div>
            </div>
        </Container>
    )
}