import React, { useState } from "react";

import Container from 'react-bootstrap/Container';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Footer() {

    const [direction, setDirection] = useState<'row' | 'row-reverse' | 'column' | 'column-reverse'>('row');

    const panel: React.CSSProperties = {
        paddingTop: 50,
        paddingBottom: 50,
        backgroundColor: "#264653",
        color: 'white',
        width: "100%",
        display: 'flex'
    };

    const content: React.CSSProperties = {
        display: 'flex',
        flexDirection: direction,
    };

    const getInTouch: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
    };

    const col: React.CSSProperties = {
        flexGrow: 1,
    };

    const row: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        fontSize: 20,
        alignItems: 'baseline'
    };

    const logo: React.CSSProperties = {
        objectFit: 'cover',
        width: 200
    };

    window.addEventListener('resize', () => {
        if (window.innerWidth > 1000) {
            setDirection('row')
        } else {
            setDirection('column')
        }
    })

    return (

        <div style={panel}>
            <Container>
                <div style={content}>
                    <div style={col}>
                        <div style={getInTouch}>
                            <p style={{ fontWeight: 'bold', fontSize: 32 }}>Get in Touch!</p>
                            <div style={row}>
                                <FontAwesomeIcon icon="calendar" />
                                <p>Wed and Fri: 9am - 4pm</p>
                            </div>

                            <div style={row}>
                                <FontAwesomeIcon icon="phone" />
                                <p>(636) 538-5422 | Fax: (636) 206-8398</p>
                            </div>

                            <div style={row}>
                                <FontAwesomeIcon icon="map" />
                                <p>4171 Crescent Dr, Suite 202, St Louis, MO 63129</p>
                            </div>

                            <div style={row}>
                                <FontAwesomeIcon icon="envelope" />
                                <p>info@betterdayspractice.com</p>
                            </div>

                        </div>
                    </div>
                    <div style={col}>
                        <div style={{ textAlign: 'center' }}>
                            <img style={logo} src="../../BetterDaysWhite.png"></img>
                        </div>

                    </div>
                </div>
                <div style={{ paddingTop: 30 }}>
                    <p>DISCLAIMER:</p>

                    <p>Please note that the information provided on this website is not medical advice. It is only for informational and educational purposes. We are not responsible for the accuracy of external websites linked from this site. Please contact us directly to schedule an appointment to discuss your mental health concerns, diagnoses, or treatments. If you have a medical emergency, call 911.</p>
                    <p>Your website-related activities and communications do not create a provider-patient relationship between you and Better Days Psychiatry and Family Practice or any of its providers and do not create a duty for us to follow up with you. To learn about our services, please contact us directly.</p>
                </div>

            </Container>
        </div>
    )
}